<template>
  <div>
    <v-app-bar color="bg-dark accent-4" dense dark app clipped-left>
      <router-link to="/">
        <v-app-bar-nav-icon>
          <img
            src="https://app.yoyo.do/assets/images/Yoyo.png"
            alt=""
            width="30"
            height="30"
            class="d-inline-block align-text-top"
          />
        </v-app-bar-nav-icon>
      </router-link>
      <v-toolbar-title>Yoyo Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>

      <div>
        <v-btn text @click="signOut" class="btn-create hidden-md-and-down">
          <span>Cerrar sesión</span>
        </v-btn>
      </div>

      <div class="hidden-lg-and-up">
        <v-btn @click.stop="drawer = !drawer" icon
          ><v-icon>mdi-menu</v-icon></v-btn
        >
      </div>

    </v-app-bar>
    <v-card height="100%" v-model="drawer">
      <v-navigation-drawer v-model="drawer" app clipped left>
        <template>
          <v-list-item three-line class="">
            <v-list-item-avatar height="60px" width="60px">
              <v-avatar class="profile" size="60">
                <v-img
                  :src="
                    merchant.logo
                      ? merchant.logo
                      : 'https://app.yoyo.do/assets/images/Yoyo.png'
                  "
                ></v-img>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-semibold">{{
                merchant.companyName
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ merchant.name }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ merchant.code }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider class="m-3"></v-divider>

        <v-list dense rounded class="mt-4">
          <router-link
            :to="item.route"
            v-for="item in items.filter((x) => x.section === 'home')"
            :key="item.title"
          >
            <v-list-item :class="{ selected: item.selected }" link>
              <v-list-item-icon>
                <v-icon class="icon">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

        <label class="ml-3 mt-5">Accesos directos</label>
        <v-list dense rounded class="mt-4">
          <v-list-item
            @click="item.action"
            v-for="item in items.filter((x) => x.section === 'direct')"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon class="icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <label class="ml-3 mt-5">Otros</label>
        <v-list dense rounded>
          <router-link
            :to="item.route"
            v-for="item in items.filter((x) => x.section === 'others')"
            :key="item.title"
          >
            <v-list-item :class="{ selected: item.selected }" link>
              <v-list-item-icon>
                <v-icon class="icon">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="logout hidden-md-and-up" @click="signOut">
              <v-list-item-icon>
                <v-icon class="ico-logout">mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <link-form
      @float:created="onFloatCreated"
      @close:form="onFormClose"
      @added="onLinkAdded"
      :type="formType"
      :show="dialog"
      :merchant="merchant"
    />
  </div>
</template>
<script>
import LinkForm from "@/components/LinkForm.vue";
export default {
  data: () => ({
    dialog: false,
    drawer: null,
    formType: "",
    items: [],
  }),
  props: {
    merchant: {},
  },
  components: {
    LinkForm,
  },
  created() {
    this.items = [
      {
        title: "Home",
        icon: "mdi-home",
        route: "/",
        section: "home",
        selected: true,
      },
      {
        title: "Transacciones",
        icon: "mdi-bank-transfer",
        route: "/transactions",
        section: "home",
      },
      {
        title: "Mis Productos",
        icon: "mdi-view-grid-plus-outline",
        route: "/products",
        section: "home",
      },
      {
        title: "Generar Link",
        icon: "mdi-link-variant-plus",
        action: () => {
          this.openDialog("STANDARD");
        },
        section: "direct",
      },
      {
        title: "Crear Producto",
        icon: "mdi-plus-circle",
        action: () => {
          this.openDialog("PRODUCT");
        },
        section: "direct",
      },
      {
        title: "Visualizar Codigo QR",
        icon: "mdi-qrcode-scan",
        action: () => {
          window.open(this.merchant.qrUrl);
        },
        section: "direct",
      },
      {
        title: "Configuraciones",
        icon: "mdi-account-cog-outline",
        route: "/settings",
        section: "others",
      },
    ];
  },
  watch: {
    $route() {
      this.items = this.items.map((x) => ({
        ...x,
        selected: this.$route.path === x.route ? true : false,
      }));
    },
  },
  mounted() {},
  methods: {
    signOut() {
      localStorage.removeItem("session");
      this.$router.push("/authenticate");
    },
    onFormClose() {
      this.dialog = false;
    },
    onFloatCreated(f) {
      this.$emit("float:created", f);
    },
    onLinkAdded(f) {
      if (this.formType === "PRODUCT") this.$emit("link:added", f);
    },
    openDialog(type) {
      this.formType = type;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #494569;
  color: white !important;
}

.ico-logout {
  color: #494569 !important;
}

.logout {
  color: #494569 !important;
}

.btn-create {
  background-color: #494569;
}
</style>
