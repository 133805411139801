





















import Vue from "vue";
import Vuetify from "vuetify";
import VueClipboard from "vue-clipboard2";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import Header from "@/components/Header.vue";
import Float from "@/components/Float.vue";
// import "tailwindcss/tailwind.css";
import "@/assets/tailwind.css";
import "@/assets/styles.css";
import { clearCache, formatCurrency, renewSession } from "./utils";
import { AUTH_HEADER } from "@/config/constants";

export default Vue.extend({
  name: "App",
  components: {
    Header,
    Float,
  },
  data: () => ({
    link: undefined,
    float: { text: "", duration: 2000 },
    showHeader: false,
    merchant: {},
    childrenMerchants: [],
  }),
  watch: {
    $route(to, from) {
      this.showHeader = to.path === "/authenticate" ? false : true;
      if (!this.$route.meta?.guest) {
        this.showHeader = true;
        this.getMerchantProfile();
      }
    },
  },
  async mounted() {
    await this.updateWebsite();
    if (this.$route.meta?.guest) {
      this.showHeader = false;
    } else {
      this.getMerchantProfile();
      this.showHeader = true;
    }
  },
  methods: {
    onFloatCreated(float: any) {
      this.float = float;
    },
    onLinkAdded(l: any) {
      this.link = l;
    },
    onMerchantUpdate(data: any) {
      this.merchant = data;
    },
    async updateWebsite() {
      const siteVersion = localStorage.getItem("site-version");
      const resp = await fetch(`${process.env.VUE_APP_API_URL}/misc/versions`, {
        headers: {
          "Content-Type": "application/json",
          [AUTH_HEADER]: process.env.VUE_APP_API_KEY || "",
        },
      });

      const { ok, data } = await resp.json();
      if (ok && data) {
        const latestVersion = data.dashboard;
        if (latestVersion) {
          if (siteVersion) {
            if (siteVersion !== latestVersion) await clearCache(latestVersion);
          } else await clearCache(latestVersion);
        }
      }
    },
    async getMerchantProfile() {
      const resp = await fetch(`${process.env.VUE_APP_API_URL}/auth/profile`, {
        headers: {
          "Content-Type": "application/json",
          [AUTH_HEADER]:
            localStorage.getItem("session") || "",
        },
      });

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        this.merchant = data;
        renewSession(resp);
      }
      let error = "";
      if (!ok) {
        if (errorMessage) error = errorMessage;
        else if (errors) {
          error = errors[0].msg;
        } else {
          error = resp.statusText;
        }

        if (resp.status === 401) {
          // the user is not logged.
          this.$router.push("/authenticate");
          localStorage.removeItem("session");
        }
      }
    },
  },
});

Vue.filter("money", formatCurrency);

Vue.use(Vuetify);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueTelInput);

